.footer {
    height: 326px;
     position: relative;
    .rectangle {
        background-color: #E5F8ED;
        height: 326px;
        left: 0;
     text-align: center;
        top: 0;
        
    }
}

.footerContent {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    position: relative;
}

.footerContent .bethought-white {
    height: 84px;
    position: relative;
    width: 233px;
    padding-top: 2rem;
}

.footerContent .navbar {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
 
}

.footerContent .text-wrapper {
    color: var(--title-color);
   font-family: 'Open Sans';

    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    .link{
        color: var(--title-color);
        text-decoration: none;
    }
}


.footerContent .div {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;

}

.footerContent .img {
    height: 30px;
    position: relative;
    width: 30px;
}

.footerContent .p {
    color: #000000;
    font-family: 'Open Sans';
    font-size: var(--h2-p-17px-font-size);
    font-style: var(--h2-p-17px-font-style);
    font-weight: var(--h2-p-17px-font-weight);
    height: 20px;
    letter-spacing: var(--h2-p-17px-letter-spacing);
    line-height: var(--h2-p-17px-line-height);
    position: relative;
    white-space: nowrap;
    width: 337px;
}
@media screen and (max-width:663px) {
    .footerContent .navbar {
     gap:12px;
       
     
    }
    .footerContent .bethought-white {width:150px}
}
@media screen and (max-width:520px) {
    .footerContent .navbar {
     gap:8px;
       
     
    }}
@media screen and (max-width:700px) {
    .footerContent .navbar {
     gap:10px;
       
     
    }
}
@media screen and (max-width:450px) {
    .footerContent .text-wrapper{
        font-size: 15px;
    }
    .footerContent .navbar {
     gap:3px;
       
     
    }
    .footerContent .div{
        gap: 1px;
    }
}
@media (max-width: 1700px) {
  
}
