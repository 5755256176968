:root{
    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --button-color:#3EAC4F;
    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
    --h1FontSize:2.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:.938rem;
    --smallFontSize:.813rem;
    --smallestFontSize:.75rem;



}

.divaccount { 
    width: 25%;
    height:auto;
    background: url('../../../../assets/R.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 2rem 2.5rem;
    opacity: 1;
.account{
   
   
    .icons{
        justify-content: space-between;
        color: #fff;
        .icon{
           
            background: rgba(255,255,255,0.151);
            border-radius: 50%;
          padding: .4rem;
            height: 30px;
            width: 30px;
            :hover{
                background-color: #fff;
            }
        }
    }
    .accountDetails{
        display: grid;
        justify-content: center;
        align-items: center;
        //padding: 2rem;
        margin-top: 2rem;
    .imageDiv{
        display: grid;
        align-items: center;
        text-align: center;
        img{
            display: block;
            margin: auto;
            border-radius: 50%;
            padding: 5px;
            margin-bottom: 1rem;
            width: 70%;
            height: auto;
        }
        .name{ 
            font-size: var(--h2FontSize);
            color: #fff;
            font-weight: 500;
        }
       .position{
        padding: .5rem 0;
        color: #fff;
        opacity: .6;
        font-size: var(--smalleFontSize);
       }
       

    }
    .btn{
       
        margin: auto;
        width: 150px;
        border-radius: 50rem 50rem 50rem 50rem;
        height: 50px;
       display: flex;
       justify-content: center;
        background-color: #fff;
          padding: 0.5rem;
        .icon{
            width: 20px;
            height: 20px;
        }
        &:hover{
            background-color: #00B74F;
        }
       }
    .darkMode{
        margin-top: 5rem;
        text-align: center;
        .title{

            color: #fff;
            font-weight: 500;
            padding-bottom: .5rem;
            font-size: var(--h3FontSize);
            display: block;
            margin-bottom: .3rem;
        }
        .cardDark{
            margin: auto;
          
            background: #e9e9e9;
            justify-content: space-between;
            width: 150px;
            height: 50px;
            gap: 1rem;
            border-radius: 50rem 50rem 50rem 50rem;
            .right , .left{
               
                span{
                   
                   padding-bottom: .3rem;
                   .icon{ 
                    font-size: 1.5rem;
                   }

                }
            }
            .bg {
                display: none;
                background-color: #000;
                color: #fff;
                padding: .8rem 0.8rem .8rem 0.8rem;
                border-radius: 50%;
            }
            .nobg{
                padding: .8rem 0.8rem .8rem 0.8rem;
                border-radius: 50%;
               
                color: yellow;
                background:#fff;
            }

        }

    }
    
    }
    
}}

.hidden{
   
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    opacity: 0;width: 0%;
   display: none;

}
div  .iconContainer{

    padding-right: 1rem;
    padding-top: 1.5rem;
}
div .iconContainer .linebare{
   
    color: rgba(0, 0, 0, 0.64);
    background-color:hsl(217, 50%, 97%);
    border-radius: 50%;
      padding: .4rem;
            height: 40px;
            width: 40px;
            cursor: pointer;
            margin-left: 3px;
    margin-top: -30px;

            
}
.linebare:hover{
    background-color: #329942;
    color: #fff;
}