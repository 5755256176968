
.btn{
    background-color: var(--button-color);
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    padding: .8rem 1rem;
  }
  .btn .icon{
    margin-left: .5rem;
  }
  .btn:hover{
    background-color: var(--button-hover);
  }
  .btn a{
    color: var(--alt-background);
    font-weight: 700;
  }
  .section{
    padding: 4rem 0 2rem;
  }
:root{

    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --button-color:#3EAC4F;
    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
    --h1FontSize:2.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:.938rem;
    --smallFontSize:.813rem;
    --smallestFontSize:.75rem



}


.ftco-section {
    padding: 5em 0; 
    width: 100%;
   
    height: 100vh;
   display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  background-image: url('../../assets/R.png');

.heading-section {
    font-size: 28px;
    color: #000; 

    .img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; }}

        
.login-wrap {
    position: relative;
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24); 

    h3 {
        font-weight: 700;
        font-size: 20px;
        color: #8d448b; }
    .icon-admin {
        width: 80px;
        height: 80px;
        background: url('../../assets/admin.png');
        border-radius: 50%;
        font-size: 30px;
        margin: 0 auto;
        margin-bottom: 10px; }
     .icon span {
          color: #fff; }
    
.form-group {
    margin-bottom: 1rem;

    
        position: relative; 
        .text-md-left {
            a{
                color: var(--foreground);
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
            }
         }
         .text-md-right {
            text-align: right !important;
            a{
                color: var(--foreground);
                text-decoration: none;
                font-size: 16px;
                font-weight: 500;
                -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
            }
         }
         .text-md-left{
          p{
              text-decoration: none;
             
          }
         }
    
    
.form-control {
    display: block;
    height: 48px;
    font-size: 16px;
  
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    border-radius: 0.5rem;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }

   
    
   .have_account {
    display: block;
    position: relative;
    text-align: left !important;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
    input {
        
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; 
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        

    overflow: visible; 
    
    
    }
    
    input[type="submit"].btn-block{
  width: 100%; }
    



}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    
    
    border:none;
    outline: 0;
    }
  .form-control::-webkit-input-placeholder {
 
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }



    @media (prefers-reduced-motion: reduce) {
        .form-control {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
    
     
      .form-control:focus, .form-control:active {
        outline: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
       
        border-color: transparent; }

.btn {
    display: inline-block;
    font-weight: 700;
    color: var(--alt-background);
    text-align: center;
    vertical-align: middle;
    background-color: var(--greencolor);
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 18px;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }

    }
       .submit {
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          margin: 0 auto; }
          @media (max-width: 767.98px) {
           .submit {
              top: 0; } }
       


}
  
  }