.newArticle{

    width: 100%;
    gap: 20px;
    display: flex;
    margin-top: 20px;
    .content{ 
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;
       
     
            input{
                padding: 10px;
                border: 1px solid lightgray;
                width: 100%;
                
            }
            .ck.ck-editor__main>.ck-editor__editable{
              height: 84vh;
              border: 1px solid #ccced1;
            }
        
     
    }
    .menu{
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-right: 10px;
        .item1{
            border: 1px solid #ccced1;
            padding: 10px;
           
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 12px;
            color:  rgba(0, 0, 0, 0.64);
            .file{
                text-decoration: underline;
                cursor: pointer;
            }
            .buttons{
                display: flex;
                justify-content: space-between;
                padding-top: 10px;
            button{
                    cursor: pointer;
                    background-color: #3EAC4F;
                    color: #fff;
                    padding:3px 5px;
                    border: 1px solid #3EAC4F;
                    border-radius: 50rem;
                    width: 100px;
                                       }
                                                         
                                      
            }
        }
        .item{
            border: 1px solid #ccced1;
                padding: 10px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 12px;
                color:  rgba(0, 0, 0, 0.64);
                h1{
                    font-size: 20px;
                } 
                span{
                    b {
                        padding-right: 1rem;
                    }
                }
               
                .cat{
                    display: flex;
                    align-items: center;
                    color: #3EAC4F;
                    label{
                        padding-left: 1rem;
                        font-size: 1rem;
                    }
                }
        
            }

    }
}