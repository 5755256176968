
:root{

    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    
    --chakra-colors-green-200: #9AE6B4;
    --chakra-colors-green-300: #68D391;
    --chakra-colors-green-400: #48BB78;
    --button-color: #38A169;
    --chakra-colors-green-600: #2F855A;
    --chakra-colors-green-700: #276749;
    --chakra-colors-green-800: #22543D;
    --chakra-colors-green-900: #1C4532;

    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
}

.home {
    height: 70vh;
    background:  url('../../../assets/hero-bg.png');
   
    height: 100vh;
    padding-top: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   
    .effectImg{
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.6;
    background-image: linear-gradient(to bottom right,#002f4b,#000);

   }
    .home__container{
              position: relative;

        display: grid;
        grid-template-columns: 2fr 1fr; /* Two columns with equal width */
        gap: 2rem; /* Adjust the gap between columns */
        .home__content{
            display: grid;
            grid-template-columns: 1fr; /* Single column layout for the content */
            align-items: center;
          .home__title{
                font-size: 3.75rem;
                font-family: var(--font-body);
                   margin-bottom: 0.25rem;
                   color: var(--alt-background);
                    }
                    .home__description{
                        text-align: start;
    padding-left: 0.75rem;
    border-left: 3px solid var(--alt-background);
    line-height:  1.625;
    max-width: 36rem;
    display: block;
   
    font-size: 1.25rem;
    letter-spacing: 0.025em;
                        span:first-of-type{
                            font-weight: 600;  
                            
                        }
                        text-align: left;
                    
                       font-family: var(--font-body);
                          margin-bottom: 3rem;
                          color: var(--alt-background);
                      }  
    }
    .home__img {
          
        animation: kodama-animate infinite 2s;
    }
    
@keyframes kodama-animate {0% {
transform: translateY(0px);
}
50% {
transform: translateY(-30px);
}
100% {
transform: translateY(0px);
}

}

.HomeSearch{
    display: flex;

   .searchDiv{

    input{
        width: 600px;
        background: var(--alt-background);
        border: none;
        
        height: 60px;
        border: none;
        outline: none;
        padding: 0 45px;
        border-radius: 15px 0 0 15px;
        &::placeholder{
            font-size:1.25rem;
        font-weight: 500;
           
            opacity: .5;
            
        }
        &:focus{
            outline: none;
        }
    }
   }

   
   .searchButton{
    
    position: relative;
    border-radius: 0px 15px 15px 0px;
    height: 60px;
    border: none;
    width: 200px;
    cursor: pointer;
    outline: none;
    color: white;
   
    color: var(--alt-background);
   background: var(--chakra-colors-green-400);
   font-size: 1.25rem;
   font-weight: 600;
   
   border: none;
   &:hover{
    background: var( --button-color   
    );

   }
   }
    

}


    }
    

 
  }


  .home__img {
    order: 1; /* Make home__img appear before home__data and HomeSearch */
    text-align: center; /* Center the image horizontally */
  }
  @media screen and (max-width:1200px) {
    .home__img{
        display: none;
    }
  }

 @media screen and (max-width:1024px) {
    .home{
       padding-top: 100px;
    }
  }

 @media screen and (max-width:820px) {
    .home{
       padding-top: 350px;
    }
  }

  @media screen and (max-width:990px){

    
    .home .home__container .HomeSearch .searchDiv {

    input{
        width: 400px;
      
     
    }
   }

   
   .searchButton{
   
    width: 100px;
  
   }
    


  }


  @media screen and (max-width:768px){
.home {
       
        padding-top: 350px;
        
    }

.home .home__container .home__content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    /* left: 10px; */
    /* right: 10px; */
 
}
  }

  @media screen and (max-width:767px){
    .home .home__container .home__content .home__title {
        font-size: 2.5rem;
        line-height: 1.50;
      
    }
    
    .home .home__container .HomeSearch .searchDiv {

        input{
            width: 300px;
          
         
        }
       }
    
       
       .searchButton{
       
        width: 80px;
      
       }
        
  }
  @media screen and (max-width:545px){
    .home .home__container .HomeSearch .searchDiv {

        input{
            width: 255px;
            height: 50px;
          
         
        }
       
       }
       .home .home__container .HomeSearch .searchButton { 
        width: 200px;
         height: 50px;}
 
       .home__content{
               align-items: center;
               font-size: 12px;
               .home .home__container .home__content .home__title{
            text-align: center;
            font-size: 12px;
                }
                .home__description{
                    text-align: center;
                    padding-left: 0rem;
                    border-left: none;
                    span:first-of-type{
                        font-weight: 600;  
                        
                    }
                    text-align: center;
                }
  }}
@media screen and (max-width: 540px){
.home {
    padding-top: 190px;
}}
  @media  screen and (max-width:488px){
    .home .home__container .HomeSearch .searchDiv {

        input{
            width: 300px;
            height: 50px;
          
         
        }
       
       }
       .home .home__container .HomeSearch .searchButton { 
        width: 100px;
         height: 50px;}
 
  }


  @media  screen and (max-width:460px){

      .home .home__container .home__content .home__title {
    font-size: 2rem;
    line-height: 1.5;
}
    .home .home__container .HomeSearch .searchDiv {

        input{
            width: 200px;
            height: 50px;
          
         
        }
       
       
       }
       .home .home__container .home__content .home__description {
  
    margin-bottom: 1rem;}
       .home .home__container .HomeSearch .searchButton { 
        width: 100px;
         height: 50px;}
 
  }
 @media  screen and (max-width:375px){

.home {
       
        padding-top: 100px;
        
    }}
