:root{
    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --button-color:#3EAC4F;
    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
    --h1FontSize:2.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:.938rem;
    --smallFontSize:.813rem;
    --smallestFontSize:.75rem;



}




.Top{
    width: 100%;

    .topDiv{
       
        margin-bottom: 2rem;
        justify-content: space-between;
        .titleText{
            .title{
                font-size: 2rem;
                padding-bottom: .5rem;
                font-weight: 700;
                color:rgba(0, 0, 0, 0.64);
                 
            }
            p{
                font-weight: 600;
                opacity: .7;
                font-size: var(--h3FontSize);
            }
        }
        .searchInput{
            background: hsl(217, 50%, 97%);
            padding: .8rem 1.5rem;
            border-radius: 3rem;
        }
        .icon{
           
        }
        input{
            background: none;
            outline: none;
            border: none;
            margin-left: .5rem;   
             }
             &::placeholder{
                font-weight: 500;
                opacity: .7;
             }
       
    }
    .cardsDiv{
        align-items: flex-start;
        gap: 2rem;
        justify-content: space-between;
        .cards{
            display: grid;
            gap:1rem;
            .card{
                padding: 1rem;
                border-radius: 1rem;
                gap: 1rem;
                background-color: #909090;
                display: block;
                span{
                 
                 align-items: center;
                    padding: .7rem 1rem .7rem .7rem;
                    color: rgba(0, 0, 0, 0.64);
                    font-size: 1.25 rem;
                }
            }
        }
    }
}