
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500&family=Jost:wght@100;200;300;500;600;700&family=League+Spartan:wght@300;400;500&family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,200&family=Open+Sans:wght@300&family=Poppins:wght@200;300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500&family=Jost:wght@100;200;300;500;600;700&family=League+Spartan:wght@300;400;500&family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,200&family=Open+Sans:wght@300&family=Poppins:wght@200;300;400;500&family=Raleway:wght@300;400;500;600;700&display=swap');

*
 
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
  
/**font-family: 'Montserrat', sans-serif;
**/


   
}
:root{

    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --button-color:#3EAC4F;
    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
    --h1FontSize:2.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:.938rem;
    --smallFontSize:.813rem;
    --smallestFontSize:.75rem;









}


// Deafault Styling ===================>

a{
    text-decoration: none;
}
li {
    list-style: none;
    cursor: pointer;
}
.btn{
  background-color: var(--button-color);
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  padding: .8rem 1rem;
}
.btn .icon{
  margin-left: .5rem;
}
.btn:hover{
  background-color: var(--button-hover);
}
.btn a{
  color: var(--alt-background);
  font-weight: 700;
}
.section{
  padding: 4rem 0 2rem;
}
.body{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1rem;
}
.dash{

 
  width: 100%;
  
  height: auto;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;
  display: flex; 
  overflow-y: hidden;

}
.icon{
  font-size: 2rem;
  cursor: pointer;
}
.flex{
  display: flex;
  align-items: center;
}
.layout1{
  display: flex;
  
}
.grid{
  display: grid;
  align-items: center;
}

/*
.secIntro{
  max-width: 70%;
  text-align: center;
  margin: 0 auto 2rem;
  p{
    font-size: 13px;
    color: var(--foreground);
    font-weight: 500;
  }
}
.secIntro {
  .sectitle{
color: var(--foreground);
line-height: 1.5rem;
padding-bottom: .5rem;
  }
}

****/