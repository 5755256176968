.bottom{
    margin-top: 2rem;
    gap: 2rem;
    align-items: flex-start;
    .graphiDiv{
        flex-basis: 50%;
        .title{
            font-weight: 700;
            padding: 1rem;
            display: block;
        }
    }
    .sheduleDiv{
        width: 100%;
        .singleTask{
            align-items: flex-start;
            gap: .5rem;
            justify-content: space-between;
            margin: 2rem 0;
        }
      

    }
}