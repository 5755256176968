
.btn{
    background-color: var(--button-color);
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    padding: .8rem 1rem;
  }
  .btn .icon{
    margin-left: .5rem;
  }
  .btn:hover{
    background-color: var(--button-hover);
  }
  .btn a{
    color: var(--alt-background);
    font-weight: 700;
  }
  .section{
    padding: 4rem 0 2rem;
  }
:root{

    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --button-color:#3EAC4F;
    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
    --h1FontSize:2.5rem;
    --h2FontSize:1.25rem;
    --h3FontSize:1rem;
    --normalFontSize:.938rem;
    --smallFontSize:.813rem;
    --smallestFontSize:.75rem



}

.sideBar{
  width: 14rem!important;
    height: 100vh;
    display: grid;
    align-items: flex-start;
    border-right: #f5f5f5 1px solid;
    .logoDiv{
        padding: 1rem;
        justify-content: center;
    }
    img{
        width: 130px;
    }
    .menu{
        width: 100%;
        .navItem{
          .navList{
            display: none;
            position: relative;
            display: grid;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding: 1rem 0;
            cursor: pointer;
            overflow: hidden;

            /***** الحركة تاع الخط*****/
            &::after{
              display: none;
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              width: 3px;
              height: 25%;
              border-radius: 10px;
              transition: .3s  ease;
              background-color: #00B74F;

            } &::before{
              display: none;
              content: '';
              position: absolute;
              bottom:  50%;
              right: 0;
              width: 3px;
              height: 25%;
              border-radius: 10px;
              transition: .3s  ease;
              background-color: #329942;

            }
            .icon{
              width: 100%;
              color:  rgba(0, 0, 0, 0.64);
            }
            &:hover{
              &::after, &::before{
                display: block;
              }
              .icon{
                color: #329942;
              }
            }

          }
        }
    }


}




  
  @media (max-width: 805px){
      .sideBar img {
    width: 85px;
}
   .sideBar .menu {
    width: 64%;
}
      .sideBar {
   
    width: 7rem!important;
}
  }
