.oops {
    height: 70vh;
    background:  url('../../assets/hero-bg.png');
   
    height: 100vh;
    padding-top: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   .effectImg{
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.6;
    background-image: linear-gradient(to bottom right,#002f4b,#000);

   }
    
    .oops__container{
       position: relative;
       text-align: center;
        //display: grid;
      //  grid-template-columns: 2fr 1fr; /* Two columns with equal width */
      //  gap: 2rem; /* Adjust the gap between columns */
        .oops__content{
           
            //grid-template-columns: 1fr; /* Single column layout for the content */
            align-items: center;
          
    }
    .TextOops{
        margin-bottom: 1rem;
        .TextDiv{color: #fff;
            padding-top: 1rem;
            font-family: open sans;
            h2{
                font-weight: 700;
            }
            h6{
                font-weight: 600;
                margin-top: 1rem;
                margin-bottom: 1rem;
            
            }
        }
        
        .returnButton{
    
            position: relative;
            border-radius: 15px 15px 15px 15px;
            height: 60px;
            border: none;
            width: 200px;
            cursor: pointer;
            outline: none;
            color: white;
           text-decoration: none;
            color: var(--alt-background);
           background: #00B74F;
           font-size: 1rem;
           font-weight: 600;
           
           border: none;
          
           }
    }
    




    }
    

 
  }

  
  @media (max-width: 772px){
    .oops .oops__container .oops__content .oops__img img{
        width: 400px;
    }
  }
  @media (max-width: 430px){.oops .oops__container .oops__content .oops__img img {
    width: 300px;
}}
