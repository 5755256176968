
:root{

  --background: #ffffff;
  --alt-background: #f5f5f5;
  --foreground: #1a202c;
  --primary: #3eac4f;
  --greencolor : #00B74F;
 --titlecolor: #ffff;
  --alt-primary: #329942;
  
  --chakra-colors-green-200: #9AE6B4;
  --chakra-colors-green-300: #68D391;
  --chakra-colors-green-400: #48BB78;
  --button-color: #38A169;
  --chakra-colors-green-600: #2F855A;
  --chakra-colors-green-700: #276749;
  --chakra-colors-green-800: #22543D;
  --chakra-colors-green-900: #1C4532;

  --button-hover:#00B74F;
  --destructive: #ed4343;
  --alt-destructive: #db3f3f;
  --grey-50: #e9e9e9;
  --grey-100: #e0e0e0;
  --grey-600: #909090;
  --ring: 215 20.2% 65.1%;
  --radius: 0.5rem;
  --font-body:'Poppins',sans-serif;
}

.section-about {
  padding-top: 4rem;
    padding-bottom: 4rem;
    overflow-x: hidden;

  .hh{
    padding-inline-start: 5rem;
  
    padding-inline-end: 5rem;

  }
  .about-slider {
  /* Adjust the width of about-slider as needed */

   /* flex-direction: column;
    */height: 500px;
    position: relative;
    left: 0;
   
 
    .overlap {
      position: relative;
     
      .group {
        width: 40%;
        margin-bottom: 0px;
         text-align: center;
        .overlap-group {
         
          .rectangle {
            background-color: #c79127;
            height: 120px;
            left: 420px;
            position: absolute;
            top: -30px;
            width: 120px;
 
          }
          
          .transparency {
            height: 120px;
            left: -50px;
            position: absolute;
            top: 350px;
          }
          .img {
            object-fit: cover;
            width: 500px;
            height: 420px;
    position: relative;
    z-index: 100;
   
          }
       /*   ::before{
            content: '';
            position: absolute;
            width: 120px;
            height: 120px;
            top: -20px;
           right:50px;
            background:#c79127 ;
            z-index: 0;
          } 
         ::after {
            content: '';
            position: absolute;
            width: 120px;
            height: 120px;
            left: -20px;
            bottom: -20px;
       
            background: url("../../../assets/green.png");
          
         
    z-index: 0;
    
            
        }*/
        }
      }
      .test{
      
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    position: absolute;
    bottom: -25px;
   right: 50px;
    z-index: 500;
  
      .div {
        margin: 4px;
        justify-content: center;
        width: 75px;
        height: 75px;
        
          border: 2px solid;
          border-color: #D69E2E;
          border-radius:0.75rem;
          overflow: hidden;
          cursor: pointer;
        .rectangle-2 {
          object-fit: cover;
    width: 100%;
    height:100%;
    position: relative;
    z-index: 999;
          
        }
        .rectangle-3 {
          
          border-radius: 15px;
          border: 1px solid #c79127;
          height: 126px;
          object-fit: cover;
          position: relative;
          width: 115px;
        }
      }
    }
    }
    
   
   
  }

  .div-2 {
 /*
   
    gap: 40px;
 
 
padding-top:100px;
*/width: 50%;
    position: relative;
    padding-right: 0px;
    position: relative;
    padding-left: 0rem;
    .what-is-the-purpose {
     // white-space: nowrap;

      color: var(--title-color);
      font-family: "Poppins-SemiBold", Helvetica;
      font-size: 2.25rem;
      margin-bottom: 2rem;
      font-weight: 600;
      margin-top: 0px;
    text-align: start;
      letter-spacing: 0.05em;
      line-height: 1.5;
      margin-right: auto;
      text-transform: uppercase;
      max-width: 56rem;
      span{
        background: conic-gradient(from 180.12deg at 50% 50%,#3eac4f 0deg,rgba(62,172,79,0) 300deg);
        mix-blend-mode: multiply;
    }
      }
      
     
    }
    .hello-i-bet-you {
      color: var(--body-color);
      font-family: 'Poppins',sans-serif;
      font-size: 1.25rem;
      padding-top: 10px;
      letter-spacing: 1.22;
      line-height: 1.22;
      
      width: 100%;
     
     
      span:nth-of-type(2){
        font-weight: 500;
       
      }
     
    }
   
    
    
  
}
  .section{

  
    height: 70vh;
    background: url('../../../assets/hero-bg.png');
  
    height: 100vh;
    padding-top: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .effectImg{
      position: absolute;
      height: 100vh;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0.6;
      background-image: linear-gradient(to bottom right,#002f4b,#000);
  
     }

}
  .aboutme {
   padding-top: 100px;
   
    display: flex;
    align-items: center;
    max-width: 1400px;
  width: 100%;
   // justify-content: center;
   // flex-direction: row;
    padding-inline-start:4rem;
   // padding-inline-end: 0rem;
    
}


.aboutme .div2 {
   
  
   /* display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 0%;
    max-width:64rem;
    margin-inline: 0px;
    margin-bottom: 0px;
    margin-top:1.5rem;*/
   position: relative;
.div22{
  width: 248px; height: 243px; left: 200px; top: 245px; position: absolute ; background-color: #3EAC4F;
}
img{
  width: 429px; height: 433px; position: relative;     left: -50px;

}

}
.aboutme .div {
   
  width: 80%;
    flex: 1 1 0%;
  
   // margin-inline: 0px;
    //margin-bottom: 0px;
    margin-top:1.5rem;

      padding-inline-end: 7rem;
}

.aboutme .text-wrapper {
    color: #ffffff;
    font-family: open sans;
    font-size: 3.2rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.2;
    margin-top: -1px;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    .beth{
      background: conic-gradient(from 180.12deg at 50% 50%, #3eac4f 0deg, rgba(62, 172, 79, 0) 300deg);
    }
    
}

.aboutme .p {
         position: relative;

    color: #ffffff;
     font-family: 'OPEN SANS';
    font-size: 1.70rem;
    font-weight: 500;
    line-height: 1.5;
    width:100%;
   margin-top: 1.5rem;

}



.aboutme  .div2 .group {
    height: 500px;
    width: 500px;
    position: relative;
   
}
@media (max-width:1600px){
.aboutme {
    padding-left: 3rem;
  }
 
  
}

@media (max-width:1348px){
.section-about .div-2 {
    padding-left: 5%;
  
}
  .section-about .about-slider {
  
    left: -5%;
  
}
}

@media (max-width:1342px) {
  /*.aboutme {
    
    padding-inline-start: 4rem;
  }*/
/*  .aboutme .div2 {
    
    margin-inline-end: 52px;
    
}*/
  .aboutme .div2 .div22{
   
  
      left: 121px;
    
  }
  .aboutme .div2 img {
    width: 429px;
    height: 433px;
    position: relative;
    right: 100px;
}
  
  
}

  @media (max-width: 1341px) {
  
    
.section-about .about-slider .overlap .group .overlap-group .transparency {
  
  left: -140px;
  top: 360px;
 
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  
  left: 290px;
  
  top: -20px;
 
}
.section-about .about-slider .overlap .test {
 
  right: 150px;
  
}
    .section-about .div-2 .what-is-the-purpose {
      font-size: 30px;
    }
    .section-about .about-slider .overlap .group .overlap-group .img {
      
      left: -110px;
  }
    .section-about .hh {
    
      padding-inline-end: 0rem;
  }
    .section-about .div-2 {
    
      padding-top: 0px;
      
      left: -100px;
  
      
  }
  .section-about .hello-i-bet-you span:nth-of-type(2) {
    
    white-space: nowrap;
}
    
  }
@media (max-width:1255px){
  .aboutme .text-wrapper{
    font-size: 3rem;
    white-space: normal;
    
  }
  .aboutme .div2 .group {
    height: 400px;
    width: 400px;
    position: relative;
}

  }

@media (max-width:992px){
  .aboutme {
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
}
  .aboutme{
    display: block;
  }
  .aboutme .div2{
    display:none;
  }
  .aboutme .group{
    display: none;
  }
  .aboutme p {
    text-align: center;
    margin-top:1.5rem;
   // margin-inline: auto;
    font-size:2rem;

  }
  .aboutme .text-wrapper {
    font-size: 3rem;
    white-space: normal;
    text-align: center;
}
.aboutme  .div{
 // padding-top: 100px;
      top: 100px;
    display: inline;
}
/*.section{

  
    height: 30vh;
   

    .effectImg{  height: 30vh;}
}*/
}
  
   @media (max-width: 768px) {
   
    .aboutme .p{ font-size: 2rem;}
    .aboutme .div {
      padding-top: 190px;
  }

  }
@media (max-width: 414px){
.aboutme .p {
    font-size: 1.25rem;
}
 .aboutme  {
   // padding-top: 0px;
}
}
@media (max-width: 375px){
.aboutme .p {
    font-size: 1.25rem;
}

}
@media (max-width:767px){
 
  .aboutme .text-wrapper{
    font-size: 1.5rem;
    
    
  }

  .aboutme p {
    text-align: center;
    margin-top:1rem;
  //  margin-inline: 0px;
  //  margin-bottom: 0px;

  }
  
}




 /************
  @media (max-width: 1342px) {
   

    .section-about .div-2 .flag {
     

      right: 100px;
  }
  
    
    .section-about .about-slider .overlap .group .overlap-group .rectangle {
      
      left: 500px;}
      
.section-about .about-slider .overlap .group .overlap-group .transparency {
 
  left: 130px;
  top: 360px;}
  .section-about .about-slider .overlap .div .rectangle-2{
    width: 100px;
    height: 100px;
  }
  .section-about .about-slider .overlap .div .rectangle-3{
    width: 100px;
    height: 100px;
  }
  .section-about .about-slider .overlap .div {
   
    left: 350px;
   
    top: -250px;}

  }*****/
  @media (max-width: 1325px){
    .section-about .about-slider .overlap .test {
    right: 130px;
}}
@media (max-width: 1298px){
  .section-about .about-slider .overlap .test {
  right: 110px;
}}
@media (max-width: 1261px){
  
  
  
  .section-about .hello-i-bet-you {
    
    padding-top: 0px; 
    
}
}
@media (max-width: 1254px){
  .section-about .about-slider .overlap .test {
  right: 100px;
}}
@media (max-width: 1256px){
  .section-about .hello-i-bet-you span:nth-of-type(2) {
    
    white-space: normal;
}
.section-about .div-2 .what-is-the-purpose {
  white-space: normal;}
  .section-about .about-slider .overlap .group .overlap-group .img {
    object-fit: cover;
    width: 400px;
    top: 60px;}
    .section-about .about-slider .overlap .group .overlap-group .rectangle {
      left: 200px;
      top: 30px;
  }
  .section-about .about-slider .overlap .group .overlap-group .transparency {
    
    top: 300px;
}
.section-about .about-slider .overlap .test {
  right: 180px;
  top: 345px;
}
.section-about .div-2 {
  
  left: -150px;
}
}
@media (max-width: 1175px) {
  .section-about .div-2 {
    left: -90px;
}
  .section-about .about-slider .overlap .group .overlap-group .img {
   
    width: 350px;
    top: 100px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 150px;
  top: 80px;
}
.section-about .about-slider .overlap .test {
  right: 200px;
  top: 345px;
}

@media (max-width: 1119px) {
  .section-about .about-slider .overlap .test {
    right: 180px;
    top: 345px;
  }
}
}
@media (max-width: 1075px) {
  .section-about .about-slider .overlap .test {
    right: 170px;
    top: 345px;
  }
}
@media (max-width: 1064px) {
  .section-about .about-slider .overlap .test {
    right: 160px;
    top: 345px;
  }
  
}

@media (max-width: 1108px) {
  .section-about .about-slider .overlap .test {
    right: 150px;
    top: 345px;
  }
  
}
@media (max-width: 1063px) {
  .section-about .about-slider .overlap .test {
    right: 120px;
    top: 345px;
  }
}



  /*****
  @media (max-width: 1279px) {
  
    .section-about .div-2 .flag {
    
      top: 60px;}
      .section-about .about-slider .overlap .group .overlap-group .transparency {
        height: 120px;
        left: 108px;}
    .section-about .div-2 .what-is-the-purpose {
      padding-top: 10px;
      font-size: 30px;
    }
    .section-about .div-2 .hello-i-bet-you p{
      padding-top: 0px;
    }
    
    .section-about{
      display: block;****/
     /********
  justify-content: center; /* Horizontally center */
 /***** align-items: center; /* Vertically center */
   /*********** }
    
    .section-about .div-2{
      
      padding-top: 50px;
     
    }
    
    /****
    .section-about .about-slider .overlap .group .overlap-group .img{
      left: 200px;
      height: 500px;
      width: 500px;
    }
    .section-about .about-slider .overlap .div {
      
      left: 390px;}
      .section-about .about-slider .overlap .group .overlap-group .rectangle {
     left: 620px;
      }
      .section-about .about-slider .overlap .group .overlap-group .transparency {
       nonononon
        left: 150px;}
  }******/
 /**********
  @media (max-width: 1242px) {
   
  .section-about .div-2 .flag {
    top: 20px;
}

}

@media (max-width: 1236px) {
  .section-about .div-2{
    padding-top:20px;
  }
   .section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 450px;
}}



@media (max-width: 1200px) {
   }
      @media (max-width: 1157px) {
        .section-about .about-slider .overlap .group .overlap-group .rectangle {
          left: 450px;
      }
      .section-about .about-slider .overlap .group .overlap-group .transparency {
        height: 120px;
        left: 90px;
    }
        }
          @media (max-width: 1135px) {
            .section-about .about-slider .overlap .group .overlap-group .transparency {
              height: 120px;
              left: 90px;
          }
            .section-about .about-slider .overlap .group .overlap-group .rectangle {
              left: 430px;
          }
         
          }
          @media (max-width: 1091px) {
            .section-about .about-slider .overlap .group .overlap-group .transparency {
              height: 120px;
              left: 70px;
          }
          .section-about .about-slider .overlap .div {
            left: 200px;
        }
        }
        @media (max-width: 1030px) {
          .section-about .about-slider .overlap .div {
            left: 20px;
        }
        }
              @media (max-width: 1075px) {
                .section-about .div-2 .flag {
                  top: 10px;
                  right: 0px;
              }
                .section-about .div-2 {
                  
                    padding-top: 0px;
               
                  padding-left: 3rem;
              }
              
                .section-about .about-slider .overlap .div {
                  left: 200px;}
                  .section-about .about-slider .overlap .div .rectangle-2 {
                    height: 110px;
                   
                    width: 100px;
                }
                .section-about .about-slider .overlap .div .rectangle-3 {
                  height: 110px;
                 
                  width: 100px;
              }}
              @media (max-width: 1091px){
                .section-about .about-slider .overlap .group .overlap-group .rectangle {
                    left: 420px;
                }
                .section-about .about-slider .overlap .div {
                  left: 200px;
              }}
                @media (max-width: 1022px){
                  .section-about .about-slider .overlap .group .overlap-group .img {
                 
                  left: 0px;}
                  .section-about .about-slider .overlap .group .overlap-group .rectangle {
                    left: 380px;
                }
                .section-about .about-slider .overlap .group .overlap-group .transparency {
                 
                  left: 0px;
              }}
            
              @media (max-width: 980px) {
                
                .section-about .about-slider .overlap .group .overlap-group .img {
               
                  left: 20px;

              }
              .section-about .about-slider .overlap .group .overlap-group .rectangle {
                left: 400px;
            }
            .section-about .about-slider .overlap .div {
              left: 190px;
          }}

          @media (max-width: 975px) {
          .section-about .about-slider .overlap .group .overlap-group .rectangle {
            left: 380px;
        }
        .section-about .about-slider .overlap .div {
          left: 170px;
      }
      }

  @media (max-width: 825px) {

  
    .section-about .div-2{
      
      padding-left: 0px;
      text-align: center;
    }
    .section-about .about-slider .overlap .group .overlap-group .img{
      left: 150px;
      height: 500px;
      width: 500px;
    }
    .section-about .about-slider .overlap .div {
      
      left: 340px;}
      .section-about .about-slider .overlap
       .group .overlap-group .rectangle {
     left: 550px;
      }
      .section-about .about-slider .overlap 
      .group .overlap-group .transparency {
       
        left: 90px;}

  }
*****/
  @media (max-width: 991px){

    .section-about .about-slider .overlap .group .overlap-group .img {
      height: 500px;
      width: 644px;
      top: 0px; 
      left: -70px;
  }
  .section-about .about-slider .overlap .group .overlap-group .rectangle {
    left: 480px;
    top: -20px;
}
.section-about .about-slider .overlap .test {
 
  right: -100px;
  top: 390px;

}
.section-about .about-slider .overlap .group .overlap-group .transparency {
  left: -90px;
  top: 360px;
}
    
    .section-about .div-2 {
      padding-top: 0px;
     
  }
  .section-about .hello-i-bet-you {
    text-align: center;

  }
  .section-about .div-2 .what-is-the-purpose {
    text-align: center;
}
.section-about .about-slider .overlap {
  position: relative;
  width: 500px;
}

    .section-about .col-md-6 {
      
      width: 100%;
  }}
  @media (max-width: 871px){
    .section-about .about-slider .overlap .group .overlap-group .img {
      height: 500px;
      width: 644px;
      top: 0px;
      left: -100px;
  }
  .section-about .about-slider .overlap .group .overlap-group .transparency {
    left: -130px;
    top: 360px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 450px;
  top: -20px;
}
.section-about .about-slider .overlap .test {
  right: -70px;
  top: 390px;
}


  }

  @media (max-width: 788px){
    .section-about .about-slider .overlap .group .overlap-group .img {
      height: 500px;
      width: 644px;
      top: 0px;
      left: -130px;
      
  }
  .section-about .about-slider .overlap .group .overlap-group .transparency {
    left: -170px;
    top: 360px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 440px;
  top: -20px;
}
  }
 
  @media (max-width: 766px){
    .section-about .about-slider .overlap .group .overlap-group .transparency {
      left: -120px;
    }
    .section-about .about-slider .overlap {
      position: relative;
      width: 300px;
  }
  .section-about .about-slider .overlap .group .overlap-group .rectangle {
    left: 400px;
    top: -20px;
}
.section-about .about-slider .overlap .test {
  right: -220px;
  top: 390px;
}

.section-about .about-slider .overlap .group .overlap-group .img {
  height: 500px;
  width: 600px;
  top: 0px;
  left: -100px;
}
  }

  @media (max-width: 735px){
    .section-about .about-slider .overlap .group .overlap-group .img {
      height: 500px;
      width: 600px;
      top: 0px;
      left: -130px;
  }
  .section-about .about-slider .overlap .group .overlap-group .transparency {
    left: -150px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 380px;
  top: -20px;
}
    
  }

  @media (max-width: 716px){
    .section-about .about-slider .overlap .group .overlap-group .img {
      height: 400px;
      width: 450px;
      top: 0px;
      left: -50px;
  }
  .section-about .about-slider .overlap .group .overlap-group .rectangle {
    left: 300px;
    top: -20px;
}
.section-about .about-slider .overlap .group .overlap-group .transparency {
  left: -70px;
  top: 300px;
}
.section-about .about-slider .overlap .test {
  right: -130px;
  top: 350px;
}
  }
  @media (max-width: 669px){

    .section-about .about-slider .overlap .group .overlap-group .img {
     
      left: -100px;
  }
  .section-about .about-slider .overlap .group .overlap-group .transparency {
    left: -130px;
    top: 300px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 250px;
  top: -20px;
}
.section-about .about-slider .overlap .test {
  right: -90px;
  top: 350px;
}
  }
  @media (max-width: 599px){

    .section-about .about-slider .overlap .group .overlap-group .img {
     
      width: 500px;
    
      left: -150px;
  }
  .section-about .about-slider .overlap .group .overlap-group .transparency {
    left: -160px;
    top: 300px;
}
.section-about .about-slider .overlap .test {
  right: -60px;
  top: 350px;
}
  }
  
  
  
@media (max-width:569px){
  .section-about .about-slider .overlap .group .overlap-group .img {
    width: 450px;
    left: -130px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 220px;
  top: -20px;
}
.section-about .about-slider .overlap .test {
  right: -30px;
  top: 350px;
}
.section-about .about-slider .overlap .group .overlap-group .transparency {
  left: -150px;
  top: 300px;
}
.section-about .about-slider .overlap .test {
  right: -30px;
  top: 350px;
}
}
 
@media (max-width:535px){
  .section-about .div-2 {
    
    margin-top: -150px;
}
  .section-about .about-slider .overlap .group .overlap-group .img {
    width: 400px;
    height: 300px;
    left: -120px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 190px;
  top: -20px;
}
.section-about .about-slider .overlap .test {
  right: -10px;
  top: 250px;
}
.section-about .about-slider .overlap .group .overlap-group .transparency {
  left: -150px;
  top: 200px;
}
}
@media (max-width: 512px){
  .section-about .about-slider .overlap .group .overlap-group .img {
    width: 400px;
    height: 300px;
    left: -150px;
}
.section-about .about-slider .overlap .group .overlap-group .transparency {
  left: -170px;
  top: 200px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 160px;
  top: -20px;
}
.section-about .about-slider .overlap .test {
  right: 0px;
  top: 250px;
}
}
@media (max-width: 496px){
  .section-about .about-slider .overlap .test {
    right: 30px;
    top: 250px;
  }
  .section-about .about-slider .overlap .group .overlap-group .rectangle {
    left: 150px;
    top: -20px;
}
}





@media (max-width: 414px){
.section-about .about-slider .overlap .group .overlap-group .img {
    height: 300px;
    left: -150px;
    width: 350px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
    left: 100px;
    top: -20px;
}
  .section-about .about-slider .overlap .test {
    right: 80px;
    top: 250px;
}

}

@media (max-width: 375px){
  .section-about .about-slider .overlap .group .overlap-group .img {
    width: 300px;
    height: 200px;
    left: -150px;
}
.section-about .about-slider .overlap .group .overlap-group .transparency {
  left: -170px;
  top: 220px;
  height: 100px;
  width: 100px;
}
.section-about .about-slider .overlap .group .overlap-group .rectangle {
  left: 80px;
  top: -20px;
  width: 100px;
  height: 100px;
}
.section-about .about-slider .overlap .test .div {
  
  width: 60px;
  height: 60px;
 
}
.section-about .about-slider .overlap .test {
  right: 130px;
  top: 250px;
  z-index: 500;
}
.section-about .about-slider .overlap .group .overlap-group .img {
 
  z-index: 100;
}

}
 

   
