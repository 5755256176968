.firstDiv{
    
    background: url("../../../assets/hero-bg.png");
   
    
    padding-top: 180px;
    height: 755px;
    
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
 margin-bottom: 370px;
        .effectImg{
    position: absolute;
    height: 755px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.6;
    background-image: linear-gradient(to bottom right,#002f4b,#000);

   }
    
    .divtext {
       
     
        
            .div {
   
                display: inline-flex;
               
                flex-direction: column;
                 padding: 0px  12rem 0px;
                position: relative;

        }
        
    }

    .divtext .text-wrapper {
        color: #ffffff;
        font-family: "Poppins-SemiBold", Helvetica;
        font-size: 3rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 1.4;
        margin-top: -1px;
      
        max-width: 42rem;
        text-transform: uppercase;
        padding-top: 5rem;
    }
}
.contact{
     
   
     

    .contactCard{
       width: 80%;
       padding-top: 4rem;
       padding-bottom: 4rem;
       flex-direction: row;
       align-items: center;
      
       max-width: 900px;
       background-color: #f8fff9;
       transform: translateY(10%);
       
       padding-inline-start: 4rem;
       padding-inline-end:4rem;
   
       margin-inline: auto;
       color: #fff;
       border-radius: 0.5rem;
       display: flex;
   
    align-items: center;
  
    justify-content: center;
    background-image: linear-gradient(to left top, rgb(96, 144, 131), rgb(107, 201, 157));
    
   
     
     
   .contact-info{
    width: 50%;
    margin-bottom: 0px;
    padding-right: 1rem;
   
p:nth-of-type(1) {
 
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 3rem;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    
    top: 0;
    }
    p:nth-of-type(2) {
     
        font-family: "Poppins-SemiBold", Helvetica;
        font-size: 1.125rem;
        font-weight: 400;
        letter-spacing: 0.025em;
        line-height: 1.5;
        text-align: start;
       max-width: fit-content;
        margin-top: 1.25rem;
        }
        .mail{
            padding-top:2.5rem ;
            padding-bottom:2.5rem ;
        }

}
.col-6:nth-of-type(2){
    
        color: rgb(11, 14, 63);
}
    form{
        margin: 2rem;
        padding: 2rem 2rem 2rem 2rem;
       width: 100%;
       border-radius: 0.5rem;
   
        background-color: #FFF;
      
        label{ 
            color:#0B0E3F;
            
            font-family: "Poppins-Medium", Helvetica;
font-size: 1rem;
font-weight: 500;

display: block;
text-align: start;

margin-inline-end: 0.75rem;
margin-bottom: 0.5rem;

opacity: 1;
        }
        input{
           
            border: 0;
        
      
   
            width: 100%;
            min-width: 0px;
            outline: transparent solid 2px;
    min-width: 0px;
    outline-offset: 2px;
    position: relative;
    appearance: none;

    font-size: 1rem;
    padding-inline-start: 2.5rem;
    padding-inline-end: 1rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: #E2E8F0;
    background: inherit;
       font-family: "Open sans";

    &::placeholder{
        color: #718096;
        
    }

        
        }
       
        textarea{
           color: #718096;
            height: 8rem;
            
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
                font-family: "Open sans";
        }
        .form-group{padding-bottom: 1rem;}
        button{
            
            background-color: #38A169;
            position: relative;
            white-space: nowrap;
            vertical-align: middle;
            outline: 2px solid transparent;
            outline-offset: 2px;
            line-height: 1.2;
            border-radius:0.375rem;
            font-weight: 600;
           height: 2.5rem;
            min-width:2.5rem;
            font-size:1rem;
color: #FFF;

        }
    }
    .col-6{
        color: #FFF;
        p{
            font-family: "Poppins-SemiBold", Helvetica;
            font-size: 28px;
            font-weight: 600;
          
            letter-spacing: 0;
            line-height: normal;
        }
        .mail{
           
            text-decoration: none;
            padding-top: 2.5rem;
           
            img{
               
                    height: 25px;
                
            }
            span{
                color:#fff;
                margin-left: 0.5rem;
                font-weight: 700;
              
            }
        }
        .div{
            padding-top: 2rem;
         
            justify-content: flex-start;
          
            
           
            margin-top: 2.5rem;
            align-items: center;
            display: inline-flex;
           
           
            
            justify-content: center;
            a{
                margin-right:1.25rem;
               

            
             .img {
                height: 3rem;
                padding:10px;
               
                position: relative;
                min-width: 3rem;
               
                
                }
            :hover{
               
                background-color: #22543D;
                border-radius: 50%;
               
               
                
            }}
                
        }
       
       
    }
   
    
}
}


@media (max-width:1200px) {
    .firstDiv {
        
        margin-bottom: 450px;
    }}
@media (max-width:1062px) {
    .contact .contactCard .col-6 .div a {
        margin-right: 0rem;
    }
    .contact .contactCard .col-6 p {
        font-size: 20px;
       
    .contact .contactCard form label {
       
        
      
        font-size: 18px;
    }
    
}}
@media (max-width:1035px) {

    .firstDiv .divtext .text-wrapper {
      
        padding-top: 0rem; 
    }
  
   }

   @media (max-width:992px) {
.firstDiv {
    
    margin-bottom: 500px;
}}


@media (max-width:891px) {
    .contact .contactCard .contact-info p:nth-of-type(1){
        font-size: 1.875rem;
    }
    .contact .contactCard form {
        margin-left: 0rem;
        margin-top: 2rem;
        margin-right: 2rem;
        margin-bottom: 2rem;}
    .contact .contactCard .contact-info p:nth-of-type(2) {
        
       
        text-align: center;
        }
    .contact .contactCard form button {
          
        height: 40px; 
         font-size: 15px; 
      
        width: 100%;
    }
    .contact .contactCard .col-6 .div {
       
        text-align: center;
        justify-content: center;
    }

    .contact .contactCard .col-6 {
   
    width: 100%;
    }
.contact .contactCard .col-6 .lastDiv img {
       
    display: none;
}

.contact .contactCard .contact-info {
   
   
    text-align: center;
}
.contact .contactCard form label {
       
        
      
    font-size: 15px;
}

.contact .contactCard {
    
    transform: translateY(5%);}
    .firstDiv {
       // padding-top: 100px;
    }
}


@media screen and (max-width:767px){
    
    .contact .contactCard .contact-info p:nth-of-type(2) {
      
        max-width: fit-content; 
        
    }
    .firstDiv .divtext .div {
    
        padding: 0px 1rem 0px;
    }
   
    .contact  .contactCard{
        width: 90%;
       // transform: translateY(15%);
    }

    .firstDiv .divtext .text-wrapper {
        font-size: 2.5rem;
        line-height: 1.50;
      
    }}
    @media (max-width:733px) {
        .contact  .contactCard{
            width: 90%;
            //transform: translateY(15%);
        }

    }
    @media screen and (max-width:545px){
        .firstDiv .divtext .text-wrapper {
            font-size: 2rem;
            line-height: 1.5;
        }
        .contact .contactCard {
           
            padding-inline-start: 1rem;
            padding-inline-end: 1rem;
           
        }
    }
    @media screen and (max-width:505px){
        .firstDiv .divtext .text-wrapper {
            font-size: 1.5rem;
            
        }
        .contact .contactCard .col-6 p {
            font-size: 18px;
        }
        .contact .contactCard form input {
           
             height: 40px; 
        }
        .contact .contactCard form button {
          
            height: 40px; 
             font-size: 15px; 
          
            width: 100%;
        }
        .contact .contactCard form textarea {
            height: 150px;
        }
    }
    @media screen and (max-width:474px){
        .contact .contactCard .col-6 .div a {
            margin-right: -1rem;
        }
        .contact .contactCard .col-6 a span {
            
            margin-left: 0rem;
          
        }
        .contact .contactCard .col-6 .div {
            gap: 20px;
            
        }
    }
 @media screen and (max-width:375px){
        .contact .contactCard .col-6 .div {
            gap: 1px;
            display: flex;
            padding-top: 0px;
        }
        .contact .contactCard {
            
             padding-inline-start: 0rem;
             padding-inline-end: 0rem; 
            
        }
        
    }
