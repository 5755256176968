@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@200;400;500&display=swap');
:root{

    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --chakra-colors-green-200: #9AE6B4;
    --chakra-colors-green-300: #68D391;
    --chakra-colors-green-400: #48BB78;
    --button-color: #38A169;
    --chakra-colors-green-600: #2F855A;
    --chakra-colors-green-700: #276749;
    --chakra-colors-green-800: #22543D;
    --chakra-colors-green-900: #1C4532;

    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
    --text-primary: serif;

}
.centered-link{
    font-size: 0.89rem;
    color: rgb(107, 107, 107);
  padding-top: 0;
        justify-content: center;
        display: flex;

}
.flex{
    display: flex;
    

}
.none{
    display: none;
}
.grid{
    display: grid;
    align-items: center;
}



.lounge{
    padding-top: 2rem;
    .sectionContainer{
        gap: 1.5rem;
      order: 2;
      
        .imgDiv{
            
           
            img{
                margin-top: 1rem;
                width: 100%;
               border-radius: 25px;
               height: 30rem;
               
            }
        }
        .textDiv{
            flex-direction:column;
            align-items: flex-start;
   
           .title{
           padding-top: 2rem;
            font-size: 3rem;
              line-height: 1.2;
             
           }
           h2{
            font-size: 1.5rem;
           }
            .grids{
                margin-top: 1rem;
                gap: 1.5rem;
                .singleGrid{
                    
                    span:first-of-type {
                       
                        font-size: 0.875rem;

                        
                        color: rgb(87, 87, 93);
                       
                        width: fit-content;
                        align-self: flex-end;
                        }
                        span{
                            font-size: 0.875rem;

                        color: rgb(87, 87, 93);
                       
                        width: fit-content;
                      ;
                        }
                      

                    .gridTitle{
                       
                        padding-bottom: .5rem;
                        font-weight: 500;
                        color: var(--blackColor);
                        
                        
                    }
                    .gridTextContainer {
                        max-width: 100%; 
                    p{
                        white-space: normal; /* Prevent text from wrapping */
                        overflow: hidden; /* Hide text that overflows the container */
word-wrap: break-word;                        font-size: 1.125rem;
                        font-family: var(--font-body);
                       
                        width: 100%;
                       line-height: 1.4;
                       margin-inline: 0px;
                       margin-bottom: 12px;
                       letter-spacing: 0.025rem;
                       color: rgb(87, 87, 93);                      
                       

                    }}
                }

             
   .readButton{
    
    position: relative;
    border-radius: 15px 15px 15px 15px;
    height: 3rem;
    border: none;
    width: 150px;
    cursor: pointer;
    outline: none;
    color: white;
   margin-top: 1rem;
    color: var(--alt-background);
   background: var(--chakra-colors-green-400);
   font-size: 1.125rem;
   font-weight: 600;
   
   border: none;
   &:hover{
    background: var( --button-color   
    );

   }
   }
    



            }
        }
    }
}



@media screen and (min-width:570px){

.lounge{
    .sectionContainer{
        .textDiv{
            .grids{
                grid-template-columns: repeat(2,1fr);
            }
        }
    }
}
}
@media screen and (min-width:900px){
.lounge{
    .sectionContainer{
        grid-template-columns: repeat(2,1fr);

        .textDiv{
           
            .grid{
                grid-template-columns: repeat(2,1fr);
                
            }
        }
    }
}
}

@media screen and (max-width:900px){
    .lounge .sectionContainer .textDiv .title{
        font-size: 2rem;
    }
}
@media (max-width:767px){
    .lounge .sectionContainer .textDiv .title{
    font-size: 1rem;

}
.lounge .sectionContainer .textDiv .grids .readButton{
    height: 2rem;
    width: 100px;
}
}
/***********start articles **************/
.ArticlesContainer{
    margin-inline: auto;
    max-width: 56rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    margin-bottom: 4rem;
    padding-top: 12rem;
    .input__group{
       
            width: 100%;
            display: flex;
            position: relative;
            input {
                
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
   
    font-size: 1.125rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 10px;
    border-width: 0px;
    
    background: rgba(0, 0, 0, 0.04);
    padding: 2rem;

            }
        }
        ul {
            padding-left: 0rem; 
            list-style-type: none;
            margin-top: 2rem;
            a{
                
                text-decoration: none;
                .ar-li{
                    border-radius: 10px;
                padding-inline-start: 1rem;
                padding-inline-end: 1rem;
                padding-top:1rem;
                padding-bottom: 1rem;
              
                    h2{
                        
                  font-family: Poppins,sans-serif;;
                  font-weight: 700;
                font-size: 1.25rem;
                 line-height: 1.33;
                   letter-spacing: 0.025rem;
                     margin-bottom: 1rem;
                     color: #000;
                     }
                     p{
                        margin-bottom:0.5rem;
                        color: #000;
                        letter-spacing: 0.05rem;
                     }
                     span{
                        letter-spacing: 0.025rem;
                        color:rgba(0, 0, 0, 0.64);
                        font-size:0.875rem;
                    
                     }
                }
            }
            li:hover{
                border-radius: 10px;
            
           
                background-color: rgba(0, 0, 0, 0.04);
            }
        }

    }
   

    /************single article ***************/

.bigcontainer{
    width: 100%;
    margin-inline: auto;
    max-width: 680px;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
}
    .frame {
      //  height: 2300px;
        //height:275rem;
       // position: relative;
        
        padding-top: 180px;
        padding-bottom: 2rem;
    }
    /*
    .frame .group {
       // height: 2484px;
      //  left: 0;
       // position: absolute;
       // position:relative;
        
       
    }*/
    .frame .group .p{
       p {img {
        padding-top: 1rem;
            width: 917px;
            height: 600px;
        }
    }
    iframe {
        height: 453px;
        left: 1px;
        object-fit: cover;
      
        width: 100%;
    }

    }
    
    .frame .text-wrapper {
        color: #000;
        font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    
        font-size:42px;
        font-weight: 700;
        letter-spacing: -0.011em;
        line-height: 52px;
        
       
    
        text-transform: capitalize;
        

    }
    
    .frame .overlap-group {
       /* background-image: url("../../../../assets/Rectangle\ 40.png");
        background-position: 50% 50%;
        background-size: cover;*/
        height: 453px;
        left: 1px;
       
      
       
        a .carousel-control-prev-icon ,.carousel-control-next-icon{
            background-color: #000;
            color: #fff;
            border-radius: 50%;
            padding: 1px;

        }
        
    }
    
   
   
    
    .frame .rectangle iframe {
        height: 453px;
        left: 1px;
        object-fit: cover;
      
        width: 100%;
    }
    
    .frame .p {
        color: var(--body-color);
        font-family: var(--text-primary);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        
        
    }
    
    .frame .p div p {
          
    letter-spacing: -0.003em;
    line-height: 32px;
        
    margin-top: 2.14em;
    margin-bottom: 0.54em;


    }

    .frame .p div figure.image-style-side{
          
    float: right;
    /* margin-right: 26px; */
    margin-left: 10px;
        max-width:50%;
    /* margin-top: -40px; */



    }
//image in line without caption 
  .frame .p div p img{
      width:50%;
      float:left;
      height:100%;
              max-width:50%;
      margin-right:10px;
      margin-top:-50px;

  }
   .frame .p div figure figcaption {
   text-align: center;
    color: #57575d;}

   .frame .p div figure figcaption a{

    color: #57575d;}



    
    .frame .text-wrapper-2 {
        color: var(--body-color);
         font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;

        margin-bottom: 1.5rem;
        font-size: 1.25rem;
      
        left: 1px;
        letter-spacing: 0.05em;
        line-height: 1.2;
       
        
    }
    
    .frame .i-can-remember-one {
        color: transparent;
        font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;

        font-size: 1.25rem;
        
        left: 1px;  
        letter-spacing: 0.05em;
        line-height: 1.2;
       
        
    }
    
    .frame .span {
        color: #000000cf;
         font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;
        font-size: 1.25rem;
       
        letter-spacing: 0.05em;
        line-height: 1.2;
    }
    
    .frame .text-wrapper-3 {
        color: #000000;
        font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;

        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.05em;
        line-height: 1.2;
    }
    
    .frame .text-wrapper-4 {
        color: #000000cf;
        font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;
padding-top: 4rem;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        left: 0;
        letter-spacing: 0.05em;
        line-height: 1.2;
        
       
    }
    
    .frame .the-novel-has-been {
        color: transparent;
        font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;
     font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        left: 0;
        letter-spacing: 0.05em;
        line-height: 1.2;
      
        
    }
    
    .frame .text-wrapper-5 {
        color: #000000cf;
       font-family: var(  --text-primary);
    font-weight: 400;
    font-style: normal;

        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        left: 1px;
        letter-spacing: 0.05em;
        line-height: 1.2;
      
        
    }
    
    .frame .text-wrapper-6 {
        color: #000000cf;
         font-family: var(  --text-primary);
         font-weight: 600;
    font-style: normal;

        font-size: 1.25rem;
       
        left: 1px;
        letter-spacing: 0.05em;
        line-height: 1.2;
      
        
    }
    
    .frame .text-wrapper-7 {
        color: #82888c;
        font-family: "Poppins-Medium", Helvetica;
        
        font-size: 12px;
        font-weight: 500;
        left: 254px;
        padding-top: 70px;
        letter-spacing: 0;
        line-height: normal;
      
        text-align: center;
        
       a{
        color: #82888c;
       }
        
    }
    
    .frame .nature-s-orchestra-a {
      
        font-family: "Poppins-Medium", Helvetica;
        font-size: 12px;
        font-weight: 500;
        left: 254px;
        letter-spacing: 0;
        line-height: normal;
      
        text-align: center;
        
       
    }
    
    .frame .text-wrapper-8 {
        color: #82888c;
        font-family: "Poppins-Medium", Helvetica;
font-size: 12px;
font-weight: 500;
left: 0;
letter-spacing: 0;
line-height: normal;

text-align: center;
    }
    
    .frame .text-wrapper-9 {
        text-decoration: none;
      color:  #82888C;
    }
    
    .frame .text-wrapper-10 {
        color: #000;
        font-family: var(  --text-primary);

        font-size: 1.50rem;
       margin-bottom: 1.25rem;
        left: 1px;
        letter-spacing: var(--h1-artical-27px-letter-spacing);
        line-height: var(--h1-artical-27px-line-height);
        
       
        font-weight: 600;
    font-style: italic;
    }
    
    .frame .text-wrapper-11 {
        color: #57575d;
        font-family: var(  --text-primary);
      
        left: 0;
        
        margin-bottom: 4rem;

        width: 100%;
        display: inline-flex;
        gap: 4px;
    }
    
    
    
    .frame .text-wrapper-11 .view {
       
        padding-left: 1rem;
        .carbon-view {
            height: 20px;
            position: relative;
            width: 20px;
        }
    }
    
  
    
    .frame .img {
        height: 492px;
        left: 1100px;
       /* position: absolute;*/
        top: 0;
        width: 40px;
    }
    
    .frame4 {
     /*  right: 10%;
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 0px;
        justify-content: center;*/
        /* or 'fixed' based on your layout */
   /*   position: fixed;*/
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding-top: 159px;
    position: FIXED;
    /* right: 10%; */
    z-index: 2;
    top: 0;
    LEFT: 90%;
        }
       
        .frame4 a .img {
        height: 40px;
        padding:7px;
         background-color: #000;
        border-radius: 50%;
       /* position: relative;*/
        width: 40px;
        
        }
        .frame4 .react-share__ShareButton{
            padding:3px;
        }
        .frame4 a:nth-of-type(1) .img:hover{
            background-color: #55ACEE;
        }
        .frame4 a:nth-of-type(2) .img:hover{
            background-color: #1877F2;
        }
        .frame4 a:nth-of-type(3) .img:hover{
            background-color: #FF4500;
        }
        .frame4 a:nth-of-type(4) .img:hover{
            background-color: #2867B2;
        }
        .frame4 a:nth-of-type(5) .img:hover{
            background-color: #464646;
        }
        .section2{padding-top: 5rem;
        }
        .section2 .col-lg-4 {
            margin-top: 2rem;
        }
.section2 .title{
    width: 100%;
    
   
        border-top: 2px solid rgb(241, 241, 241);
        border-right-color: rgb(241, 241, 241);
        border-bottom-color: rgb(241, 241, 241);
        border-left-color: rgb(241, 241, 241);
        text-align: center;
        align-self: center;
        font-size: 1.5rem;
       
        padding-top: 2rem;
    
}


        @media (max-width: 1313px){
            .frame4 {
               right: 3%;
            }}
@media (max-width:1200px) {
    .frame4{
        z-index: 1;
        align-items: center;
    gap: 30px;
  
   
    justify-content: center;
    flex-direction: row;
    justify-items: center;
    display: flex;
    background-color: #fff;
    width: 100%;
   // height: 10%;
    top: -5%;
    left: 0;
    transition:0.1s ease-in;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);;
    }
    
    .frame .img {
       
         left: 0px; 
        
    }
}        
@media (max-width: 825px){
    .section2{
        padding-top: 4rem;
    }}
@media (max-width: 768px){
    .frame .text-wrapper {
      
        font-size: 2.25rem;
      
    }
.bigcontainer {
    max-width: 940px;
}}
@media (max-width: 687px){
    .section2{
        padding-top: 10rem;
    }}
    @media (max-width: 655px){
        .section2{
            padding-top: 15rem;
        }}
        @media (max-width: 573px){
            .section2{
                padding-top: 20rem;
            }}
            @media (max-width: 563px){
                .section2{
                    padding-top: 25rem;
                }}
                @media (max-width: 526px){
                    .section2{
                        padding-top: 30rem;
                    }}
                    @media (max-width: 489px){
                        .section2{
                            padding-top: 35rem;
                        }}
                        @media (max-width: 473px){
                            .section2{
                                padding-top: 40rem;
                            }}
                            @media (max-width: 457px){
                                .section2{
                                    padding-top: 45rem;
                                }}
                                @media (max-width: 457px){
                                    .section2{
                                        padding-top: 50rem;
                                    }}
                                    @media (max-width: 404px){
                                        .section2{
                                            padding-top: 55rem;
                                        }}
                                        @media (max-width: 379px){
                                            .section2{
                                                padding-top: 60rem;
                                            }}
.fix{
    display: none;
}

