.buy {
  
   
    background:url("../../assets/sub.png");
    height: 100%;
    left: 0;
 text-align: center;
   
    position: relative;
padding-bottom: 1rem;
}
.buyContent {

align-items: center;
display: inline-flex;
flex-direction: column;

justify-content: center;

color:#fff;


}
.buyContent .label{

position: relative;
font-family: Poppins,sans-serif;

}
.buyContent .label p:nth-of-type(1){
font-size: 2.25rem;
padding-top: 5rem;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0.05rem;
font-weight: 700;
}
.buyContent .label p:nth-of-type(2){

font-size: 1.5rem;
}

.subSearch{
display: flex;

img{
    padding: 2px;
}



.buyButton{
text-decoration: none;
text-align: center;
position: relative;
border-radius: 15px ;
height: 60px;
border: none;
width: 200px;
cursor: pointer;
outline: none;
color: white;

color: var(--alt-background);
background: var(--chakra-colors-green-400);
font-size: 1.25rem;
font-weight: 600;
padding-top: 11px;
border: none;
&:hover{
background: var( --button-color   
);

}
}


}
@media (max-width: 769px) {

    .buyContent .label .text-wrapper:nth-of-type(1) {
        font-size: 1.5rem;}
    .buyContent .label .text-wrapper:nth-of-type(2){

        font-size: 1.125rem;
       }
}
