
.col-md-6 .card .card-body .card-title {font-weight: 600;
font-size:  1.25rem;
letter-spacing: 0.05em;
line-height: 1.33;
overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
font-family: Poppins,sans-serif;}
.col-md-6 .card{
    padding-left: 0;
    padding-right: 0;
    margin: 0px 30px 30px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    
    border: none;
    overflow: hidden;
    border-radius: 0.75rem;
   box-shadow:  0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.col-md-6 .card .card-img-top{
    height:  20rem;
    width: 100%;
    object-fit: cover;
}

.col-md-6 .card .card-body p{
        font-family: Poppins,sans-serif;
        margin-top: 1rem;
        color: rgb(87, 87, 93);
        letter-spacing: 0.025em;
    }

    .col-md-6 .card .card-body .readArt
    a{font-weight: 700;
    text-decoration: none;
color: #38A169;
display: inline-flex;
margin-top: 1rem;
img{
    width: 30px;
    height: 20px;

}}
    
    .view {
        color: rgb(87, 87, 93);
       
        .carbon-view {
            height: 20px;
            position: relative;
            width: 20px;
            color: rgb(87, 87, 93);
        }
    }
    





.pagicard{
  padding: 100px 10px 10px 10px;
}

.pagination{
    padding-top: 30px;
    list-style:none;
    text-decoration: none;
    display: flex;
    justify-content:center;
    align-items: center;
    font-size:1.2rem;
    gap: 20px;
}
.pagination li a{
    text-decoration: none;
}
.pagination .page-num{
    padding:8px 15px ;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
    color:#57575d;
}
.pagination .page-num:hover{
    background-color: #00B74F;
    color: #fff;
}
.pagination .active{
    background-color: #00B74F;
    color: #fff;
}
.pagination .prev,.pagination .next{
    
    
    background-color: #38a169;
    color: #fff;
    box-sizing: 3px;

}
@media screen and (max-width:990px) {
    .pagination .prev,.pagination .next{
    white-space: nowrap;
    
    }
    .pagination .page-num{
        padding:3px 10px ;
       
    }
    
}
@media screen and (max-width:663px) {
    .pagination .page-num{
        padding:4px 1px ;
       
    }
}
