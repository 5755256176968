
:root{

    --background: #ffffff;
    --alt-background: #f5f5f5;
    --foreground: #1a202c;
    --primary: #3eac4f;
    --greencolor : #00B74F;
   --titlecolor: #ffff;
    --alt-primary: #329942;
    --button-color:#3EAC4F;
    --button-hover:#00B74F;
    --destructive: #ed4343;
    --alt-destructive: #db3f3f;
    --grey-50: #e9e9e9;
    --grey-100: #e0e0e0;
    --grey-600: #909090;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --font-body:'Poppins',sans-serif;
}


.nevBarSection{
    .header{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        width: 100%;
        padding: 1rem;
        z-index: 1000;
    }
    .specific_header{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
         background: #000;width: 100%;
        padding: 1rem;
        z-index: 1000;}
    .logoDiv{
        img{
        align-items: center;
        justify-content: center;
        cursor: pointer;}
    }
    @media screen and (max-width: 900px){
        .navBar{
        
            background: var(--background);
            position: absolute;
            height: 100vh;
            padding: 1rem;
            width: 100%;
            padding-top: 5rem;
            top: 0;
            left: -500%;
            box-shadow: 0 2px 4px regb(209,209,209,0.904);
            transition: .5s ease;
            z-index:1000;
            .navLists{
                 flex-direction: column;
                //justify-content: center;
                //width: 100%;
                //margin: auto;
                margin-left: -30px;
                .navItem{
                    padding:1rem 0;
                    font-size: 1.25rem;
                    font-weight: 600;
                    .navLink{
                        color: #1a202c;
                        font-size: 1.25rem;
                    font-weight: 600;
                    text-decoration: none;
                        
                    &:hover{
                        color: #3EAC4F;
                    }
                    }
                }

            }
            .closeNavBar{
                position: absolute;
                top: 1rem;
                right: 1.5rem;
                color: var(--greencolor);
                &hover .icon{
                    color: red;
                }
            }
        }
        .activeNavBar{
        left: 0;
        }
        
    }
    .toggleNavbar{
        .icon{
            font-size: 25px;
            color: var(--alt-background);
            &:hover{
                color: var(--alt-primary);
            }
        }
    }
}

// Media Queries =================
@media screen and (min-width:901px){
    .toggleNavbar , .closeNavBar{
        display: none;
    }
    .header{
        height: 100px;
        padding: 1.5rem 2rem !important;
        
        .navBar{
            .navLists{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                .navItem{
                    .navLink{
                        color: var(--alt-background);
                        padding: 1rem;
                        text-decoration: none;
                        font-size: 1.5rem;
                         font-family: var(--font-body);
                         font-weight: 400;
                    }
                }
           
           
            }
        }
    }
    .nevBarSection .logoDiv img {
      
        width: 180px;
    }
  
}

@media screen and (max-width:901px){
    .nevBarSection .logoDiv img {
      
        width: 150px;
    }
}
.scrolled {
    background-color: black; /* Set your desired background color */
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem 0 0;
    z-index: 1000;
    height: 12vh;
  }
