.profile-section {
    padding: 2rem 0; 
   height: auto;
    
   display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 

    img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; 
    border-radius: 50%;
    width: 80%;
height: 80%;
}

        
.login-wrap {

    h3 {
        font-weight: 700;
        font-size: 20px;
        color: #8d448b; }
  
     .icon span {
          color: #fff; }
    
.form-group {
    margin-bottom: 1rem;

    
        position: relative; 
        
        
         
    
.form-control {
    display: block;
    height: 48px;
    font-size: 16px;
  
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    border-radius: 0.5rem;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }

   
    


.form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    
    
    border:none;
    outline: 0;
    }
  .form-control::-webkit-input-placeholder {
 
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }



    @media (prefers-reduced-motion: reduce) {
        .form-control {
          -webkit-transition: none;
          -o-transition: none;
          transition: none; } }
    
     
      .form-control:focus, .form-control:active {
        outline: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
       
        border-color: transparent; }

.btn {
    display: inline-block;
    font-weight: 700;
    color: var(--alt-background);
    text-align: center;
    vertical-align: middle;
    background-color: var(--greencolor);
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 18px;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }

    }
       .submit {
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          margin: 0 auto; }
          @media (max-width: 767.98px) {
           .submit {
              top: 0; } }
       


}
  
  }