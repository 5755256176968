.newArticle{
    width: 100%;
    gap: 20px;
    display: flex;
  /*  margin-top: 20px;*/
    .content{ 
       // flex: 5;
        display: flex;
        flex-direction: column;
        //gap: 20px;
       a{
            padding: 10px;
           display: flex;
           justify-content: end;
           
          
          
          .icon{
            color: rgba(0, 0, 0, 0.64);
            background-color: hsl(217, 50%, 97%);
            border-radius: 50%;
           
            border-radius:  50rem 50rem 50rem 50rem;
            height: 35px;
            width: 35px;
            &:hover{
                background-color: #3EAC4F;
                color: #fff;
              }
          } 
         
            
        }
        
.ShowArticleContainer{
    margin-inline: auto;
    
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
   /* margin-bottom: 4rem;
    padding-top: 1rem;*/
    
        ul {
            padding-left: 0rem; 
            list-style-type: none;
         /*   margin-top: 2rem;*/
            a{
              
                text-decoration: none;
                .ar-li{
                    border-radius: 10px;
                padding-inline-start: 1rem;
                padding-inline-end: 1rem;
                padding-top:1rem;
                padding-bottom: 1rem;
                border-radius: 10px;
            
           
                background-color: rgba(0, 0, 0, 0.04);
                    h2{
                        
                  font-family: Poppins,sans-serif;;
                  font-weight: 700;
                font-size: 1.25rem;
                 line-height: 1.33;
                   letter-spacing: 0.025rem;
                     margin-bottom: 1rem;
                     color: #000;
                     }
                     p{
                        margin-bottom:0.5rem;
                        color: #000;
                        letter-spacing: 0.05rem;
                     }
                     .spans.view{
                        display: flex;
                        justify-content: space-between;
                        letter-spacing: 0.025rem;
                        color:rgba(0, 0, 0, 0.64);
                       
                        font-size:1.2rem;
                        a{ 
                            color:rgba(0, 0, 0, 0.64);
                            font-size:1.2rem;}
                        .edit-delete-buttons{
                            display: flex;
                            justify-content: space-between;
                            span{
                                cursor: pointer;
                            }
                        }
                   }
                   
                }
            }
            
        }

    }
    .topDiv{
       
        margin-bottom: 0rem;
        justify-content: space-between;
        .titleText{
          
    padding-left: 1rem;
            .title{
                font-size: 2rem;
                padding-bottom: .5rem;
                font-weight: 700;
                color:rgba(0, 0, 0, 0.64);
                 
            }
            p{
                font-weight: 600;
                opacity: .7;
                font-size: var(--h3FontSize);
            }
        }
      
        input{
            background: none;
            outline: none;
            border: none;
            margin-left: .5rem;   
             }
             &::placeholder{
                font-weight: 500;
                opacity: .7;
             }
       
    }
    .pagination{
        left: -2%;
        bottom: 0;
       padding-top: 0;
        display: flex;
        align-items: center; /* Center vertically */
        justify-content: center; 
        width: 100%;
      .page-num{
            padding:4px 15px ;
            cursor: pointer;
            border-radius: 3px;
            font-weight: 400;
        }
      
    }
    }
    
}
@media screen and (max-width:990px) {
    .pagination .prev,.pagination .next{
    white-space: nowrap;
    
    }
    .pagination .page-num{
        padding:3px 10px ;
       
    }
    
}
