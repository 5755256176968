.sub {
  
   
        background:url("../../assets/sub.png");
        height: 326px;
        left: 0;
     text-align: center;
       
  
    
}

.subContent {

    align-items: center;
    display: inline-flex;
    flex-direction: column;
   
    justify-content: center;
    position: relative;
    color:#fff;
    
    
}
.subContent .label{
 
    position: relative;
   font-family: Poppins,sans-serif;
   
}
.subContent .label p:nth-of-type(1){
    font-size: 2.25rem;
    padding-top: 5rem;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
}
.subContent .label p:nth-of-type(2){
   
 font-size: 1.5rem;
}

.subSearch{
    display: flex;

   .searchDiv{

    input{
        width: 500px;
        background: var(--alt-background);
        border: none;
        
        height: 60px;
        border: none;
        outline: none;
        padding: 0 45px;
        border-radius: 15px 0 0 15px;
        &::placeholder{
            font-size:1.25rem;
        font-weight: 500;
           
            opacity: .5;
            
        }
        &:focus{
            outline: none;
        }
    }
   }

   
   .searchButton{
    
    position: relative;
    border-radius: 0px 15px 15px 0px;
    height: 60px;
    border: none;
    width: 200px;
    cursor: pointer;
    outline: none;
    color: white;
   
    color: var(--alt-background);
   background: var(--chakra-colors-green-400);
   font-size: 1.25rem;
   font-weight: 600;
   
   border: none;
   &:hover{
    background: var( --button-color   
    );

   }
   }
    

}


@media (max-width: 1029px) {
    .subContent .label .text-wrapper:nth-of-type(1) {
        padding-top: 3rem;}
}


@media (max-width: 891px){

    .sub-reso{
        margin-top: 800px;
    }
}
@media (max-width: 783px) {
.subContent .label .text-wrapper:nth-of-type(2) {
    font-size: 1.125rem;
}}
@media (max-width: 769px) {
    
    .subContent .subSearch {
            /* MARGIN: 10px; */
            display: block;}
            .subContent .label .text-wrapper:nth-of-type(1) {
                font-size: 1.5rem;}
            .subContent .label .text-wrapper:nth-of-type(2){
   
                font-size: 1.125rem;
               }
               .subContent .subSearch .searchDiv input {
                width: 400px;
             
              
                border-radius: 15px 15px 15px 15px;
            }
            .subContent .subSearch .searchButton {
                width: 400px;
                
                
                border-radius: 15px 15px 15px 15px;
            }
            .subContent .subSearch .searchDiv{ padding: 10px;}
            .sub-reso {
                margin-top: 600px;
            }
           
        
}
@media (max-width: 589px) {
    .subContent  .subSearch .searchDiv input {
    height: 50px;
 
  
}
.subContent  .subSearch .searchButton {
    height: 50px;
    
    
 
}}

@media (max-width: 537px) {
    .subContent  .subSearch .searchDiv input {
        width: 300px;
        
     
      
    }
    .subContent  .subSearch .searchButton {
        width: 300px;
        
        
     
    }}


    @media (max-width: 431px) {
    .sub {
        height: 350px;
       
    }
    .subContent .subSearch .searchDiv input {
        width: 200px;
        height: 40px;
    }
    .subContent .subSearch .searchDiv input::placeholder {
   font-size: 15px;
    }
    .subContent .subSearch .searchButton {
        font-size: 15px;
        width: 200px;
        height: 40px;
    }
}