.newContact{
    width: 100%;
    gap: 0px;
    display: flex;
  /*  margin-top: 20px;*/
    .contact{ 
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;
      
        
.ShowContactContainer{
    margin-inline: auto;
    
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
width: 100%;
    
       
    }
    .topDiv{
       
        margin-bottom: 2rem;
        justify-content: space-between; padding: 1px;
        padding-right: 1rem;
    padding-left: 1rem;
        .titleText{
            .title{
                font-size: 2rem;
                padding-bottom: .5rem;
                font-weight: 700;
                color:rgba(0, 0, 0, 0.64);
                 
            }
            p{
                font-weight: 600;
                opacity: .7;
                font-size: var(--h3FontSize);
            }
        }
      
       
    }
    .pagination{
        left: -2%;
        bottom: 0;
      
        display: flex;
        align-items: center; /* Center vertically */
        justify-content: center; 
        width: 100%;
      .page-num{
            padding:4px 15px ;
            cursor: pointer;
            border-radius: 3px;
            font-weight: 400;
        }
      
    }
    }
    
}

.icontrash{
    font-size: 1.5rem;
}
.icontrash:hover{
    color: red;
}
